<template>
  <router-view></router-view>
</template>

<script>
import Vue from "vue";
import {maxiosInternalWrapper} from "@gyrobian/mux-auth-core";

export default {
  name: "App",
  mounted() {
    /**
     * maxios is the Muxsan axios wrapper that allows us to verify tokens before actually sending a message.
     *
     * @param method
     * @param axiosArgs
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$maxios = (method, ...axiosArgs) => {
      return maxiosInternalWrapper(this.$store, this.$router, this.$route, method, ...axiosArgs)
    }
  }
}
</script>

<style scoped>

</style>