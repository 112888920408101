import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import router from './router'
import store from './store'
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import {
  initializeMuxsanAuthCore,
  verifyMultipleRightUsingAnd,
  verifyMultipleRightUsingOr,
  verifySingleRight
} from '@gyrobian/mux-auth-core'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.directive('requires-right', verifySingleRight)
Vue.directive('require-one-of-rights', verifyMultipleRightUsingOr)
Vue.directive('require-rights', verifyMultipleRightUsingAnd)

if (process.env.VUE_APP_FRONT_END_VARIANT !== "development") {
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_FRONT_END_VARIANT,
    dsn: "https://2702b46bc3394953bdc40b5d2860ec60@o1133231.ingest.sentry.io/6190793",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "fm.muxsan.io", "fm.muxsan.com", /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

initializeMuxsanAuthCore()
axios.defaults.baseURL = process.env.VUE_APP_API_URL

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#root')
