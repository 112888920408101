<template>
  <form @submit.prevent="requestBuild()">
    <BreadCrumbs></BreadCrumbs>
    <b-card class="mt-3" header="Select the firmware you need">
      <b-form-radio-group
          id="radio-group-firmwares"
          v-model="selectedFirmware"
          :options="firmwares"
          name="firmwares" required
          @change="firmwareChanged()"
      ></b-form-radio-group>
    </b-card>

    <b-card class="mt-3" v-if="selectedFirmware !== null" header="And a version">
      <b-form-radio-group
          id="radio-group-versions"
          v-model="selectedVersion"
          :options="versions"
          name="versions" required
          @change="versionChanged()"
      ></b-form-radio-group>
    </b-card>

    <template v-if="selectedVersion !== null && selectedVersion">
      <AVRBuildForm ref="innerForm" v-if="selectedFirmware.type === 'AVR'"></AVRBuildForm>
      <ExampleBuildForm ref="innerForm" v-if="selectedFirmware.type === 'EXAMPLE'"></ExampleBuildForm>
    </template>

    <div class="d-flex justify-content-center mt-3 align-items-center" v-if="selectedVersion && selectedFirmware">
      <b-button v-if="!building"
                variant="primary"
                type="submit"
      >
        Build
      </b-button>
      <b-button v-else variant="primary" disabled>
        Building
        <b-spinner small></b-spinner>
        <span class="sr-only">Building...</span>
      </b-button>
    </div>
  </form>
</template>

<script>
import BreadCrumbs from "@/components/util/BreadCrumbs";
import AVRBuildForm from "@/components/builds/types/AVRBuildForm";
import ExampleBuildForm from "@/components/builds/types/ExampleBuildForm";

export default {
  name: "BuildForm",
  components: {ExampleBuildForm, AVRBuildForm, BreadCrumbs},
  data: function () {
    return {
      firmwares: [],
      versions: [],
      selectedFirmware: null,
      selectedVersion: null,
      buildOptions: [],
      buildVars: {},
      building: false
    }
  },
  methods: {
    firmwareChanged() {
      console.log(this.selectedFirmware)
      this.selectedVersion = null
      this.versions = []
      this.$maxios('get', `firmware/${this.selectedFirmware.id}/versions?archived=false`).then(response => {
        for (const version of response.data.content) {
          this.versions.push({
            text: version.name,
            value: version
          })
        }
      })
    },
    versionChanged() {
      this.$refs.innerForm.versionChanged(this.selectedFirmware, this.selectedVersion)
    },
    requestBuild() {
      const data = this.$refs.innerForm.getData()
      this.building = true
      const url = `/firmware/${this.selectedFirmware.id}/versions/${this.selectedVersion.name}/builds?force=false`
      this.$maxios('post', url, data).then((response) => {
        this.building = false
        this.$router.push({
          name: 'build-result',
          params: {id: response.data.id}
        })
      }).catch(() => {
        this.$alerts.setAlertMessage("Something went wrong building this firmware. Try again later.", 2, 'warning')
        this.building = false
      })
    }
  },
  mounted() {
    this.$maxios('get', '/firmware/active')
        .then(response => {
          for (const vehicle of response.data.content) {
            this.firmwares.push({
              text: vehicle.name,
              value: vehicle
            })
          }
        })
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono&display=swap');

.terminal-style {
  font-family: 'Ubuntu Mono', monospace;
  background: black;
  font-size: 13px;
  line-height: 18px;
  padding-left: 3px;
  overflow-y: auto;
  max-height: 800px;
  scroll-behavior: smooth;
  width: 100%;
}

pre {
  margin-bottom: 0;
  overflow: unset;
}
</style>