<template>
  <div>
    <BreadCrumbs></BreadCrumbs>
    <div class="content-box" v-if="fetched">
      <div class="box-header">
        <h5>Edit Source</h5>
      </div>
      <b-form ref="form" @submit.prevent="" @change="verified = false">
        <SourceForm v-model="source"/>
        <input hidden ref="formSubmit">
      </b-form>
      <div class="box-actions">
        <b-button @click="verifySource()" size="sm" variant="primary" :disabled="verified || verifying || !needsSaving">
          <template v-if="verified">Verified
            <b-icon icon="check"></b-icon>
          </template>
          <template v-else-if="verifying">
            <b-spinner small></b-spinner>
          </template>
          <template v-else>Verify</template>
        </b-button>
        <b-button @click="submit()" size="sm" variant="success" class="ml-2"
                  v-if="this.verified && !this.verifying" :disabled="submitting">
          <template v-if="submitting">
            <b-spinner small></b-spinner>
          </template>
          <template v-else>Submit</template>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/util/BreadCrumbs";
import SourceForm from "@/components/settings/SourceForm";

export default {
  name: "EditSource",
  components: {SourceForm, BreadCrumbs},
  data() {
    return {
      source: {
        sourceType: "",
        directory: "",
        repositoryUrl: "",
        branchName: "",
        credentialType: "",
        httpAccessUsername: "",
        httpAccessToken: "",
      },
      verified: false,
      verifying: false,
      submitting: false,
      fetched: false,
      sourceCopy: '',
    }
  },
  computed: {
    needsSaving() {
      return this.sourceCopy !== JSON.stringify(this.source);
    }
  },
  methods: {
    buildSourceObject() {
      let source = {}
      source.type = this.source.sourceType
      if (this.source.sourceType === "directory") {
        source.directory = this.source.directory
      }
      if (this.source.sourceType === "git-repository") {
        source.repositoryUrl = this.source.repositoryUrl
        source.branchName = this.source.branchName
        source.credentials = {}
        source.credentials.type = this.source.credentialType
        if (this.credentialType === "http-access-token") {
          source.credentials.username = this.source.httpAccessUsername
          source.credentials.token = this.source.httpAccessToken
        }
      }
      return source
    },
    verifySource() {
      this.$refs.formSubmit.click()
      if (this.$refs.form.checkValidity()) {
        this.verifying = true
        console.log(this.buildSourceObject())
        this.$maxios('post', '/firmware/verifySource', this.buildSourceObject()).then(response => {
          if (response.data.valid) {
            this.verified = true
            this.verifying = false
          } else {
            this.verified = false
            this.verifying = false
          }
        }).catch(() => {
          this.verified = false
          this.verifying = false
        })
      }
    },
    submit() {
      const source = this.buildSourceObject()
      this.$maxios('patch', `firmware/${this.$route.params.id}/source`, source).then(() => {
        this.$router.push({name: 'firmware-overview'})
      })
    },
    fetchFirmwareSource() {
      this.$maxios('get', `firmware/${this.$route.params.id}`).then((response) => {
        const sourceObject = response.data.source
        if (sourceObject.repositoryUrl) {
          this.source.sourceType = 'git-repository'
          this.source.repositoryUrl = sourceObject.repositoryUrl
          this.source.branchName = sourceObject.branchName
          if (sourceObject.credentials && sourceObject.credentials.keyLocation) {
            this.source.credentialType = 'ssh-key'
          } else {
            this.source.credentialType = 'http-access-token'
            this.source.httpAccessUsername = sourceObject.credentials.username
            this.source.httpAccessToken = sourceObject.credentials.token
          }
        } else {
          this.source.sourceType = 'directory'
          this.source.directory = sourceObject.directory
        }
        this.sourceCopy = JSON.stringify(this.source)
        this.fetched = true
      })
    },
  },
  mounted() {
    this.fetchFirmwareSource()
  },
}
</script>

<style scoped>
.bottom-action-bar {
  border-top: 2px solid rgba(0, 0, 0, .125);
  display: flex;
  justify-content: flex-end;
  margin-top: .75rem;
  padding-top: .75rem;
}
</style>