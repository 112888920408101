<template>
  <b-card class="mt-3" header="Build Options" v-if="buildOptions !== null">
    <div v-for="(buildOption, index) of buildOptions" :key="index">
      <template v-if="buildOption.type === 'TEXT'">
        <b-form-group :label="buildOption.name" label-size="sm"
                      label-cols-sm="4" content-cols-sm="7" :description="buildOption.description">
          <b-form-input :required="buildOption.required" v-model="buildVars[buildOption.name]"
                        size="sm"></b-form-input>
        </b-form-group>
      </template>
      <template v-if="buildOption.type === 'FLAG'">
        <b-form-group :label="buildOption.name" label-cols-sm="4" content-cols-sm="7" label-size="sm"
                      :description="buildOption.description">
          <b-form-checkbox v-model="buildVars[buildOption.name]" switch class="pt-2"></b-form-checkbox>
        </b-form-group>
      </template>
      <template v-if="buildOption.type === 'SELECT'">
        <b-form-group :label="buildOption.name" label-cols-sm="4" content-cols-sm="7" class="mt-2"
                      label-size="sm" :description="buildOption.description">
          <b-form-select :options="buildOption.options" size="sm" v-model="buildVars[buildOption.name]"
                         :required="buildOption.required">
          </b-form-select>
        </b-form-group>
      </template>
    </div>
  </b-card>
</template>

<script>
export default {
  name: "ExampleBuildForm",
  data() {
    return {
      buildOptions: [],
      buildVars: {},
    }
  },
  methods: {
    getData() {
      return this.buildVars
    },
    versionChanged(selectedFirmware, selectedVersion) {
      this.buildOptions = null
      this.buildVars = {}
      const url = `firmware/${selectedFirmware.id}/versions/${selectedVersion.name}/buildOptions`
      this.$maxios('get', url).then(response => {
        this.buildOptions = response.data
        for (const buildOption of this.buildOptions) {
          if (buildOption.type === 'TEXT') this.buildVars[buildOption.name] = ""
          if (buildOption.type === 'SELECT') this.buildVars[buildOption.name] = null
          if (buildOption.type === 'FLAG') this.buildVars[buildOption.name] = false
        }
      })
    }
  },
}
</script>

<style scoped>

</style>