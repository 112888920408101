<template>
  <div>
    <b-card class="mt-3" v-if="buildRetrieved">
      <template #header>
        <h4 v-if="build.exitCode === 0">Build Success</h4>
        <h4 v-else>Build Failure</h4>
        <h6>Artifacts</h6>
      </template>
      <p>Click the links below to download the artifacts.</p>
      <ul>
        <li v-for="artifact of artifacts" :key="artifact.name">
          <div :class="{ expired: artifactsExpired }">
            <a :href="baseUrl + artifact.downloadToken">
              <strong>{{ artifact.name }} {{ expiredMessage }}</strong>
            </a>
          </div>
        </li>
      </ul>
    </b-card>
    <b-card v-else-if="oldBuild || fetching" class="mt-3">
      <div class="text-center">
        <div class="building-title">Fetching..</div>
        <b-spinner variant="primary"></b-spinner>
      </div>
    </b-card>
    <b-card v-else class="mt-3">
      <div class="text-center">
        <div class="building-title">Building..</div>
        <b-spinner variant="primary"></b-spinner>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "BuildResult",
  data() {
    return {
      fetching: false,
      oldBuild: false,
      build: '',
      artifacts: [],
      artifactsToBeRetrieved: -1,
      showOutput: false,
      id: '',
      baseUrl: '',
      artifactsExpired: false
    }
  },
  mounted() {
    this.oldBuild = this.$route.query.oldBuild
    this.id = this.$route.params.id
    this.baseUrl = process.env.VUE_APP_API_URL + '/builds/downloadArtifact/'
    this.getBuildStatus();
    setTimeout(() => {
      this.artifactsExpired = true;
    }, 1000*60*10)
  },
  methods: {
    getBuildStatus() {
      this.$maxios('get', `/builds/${this.id}`).then(response => {
        this.build = response.data
        if (this.build.status !== "PENDING") {
          this.getArtifacts();
          this.fetching = true;
        } else {
          setTimeout(() => {
            this.getBuildStatus()
          }, 500)
        }
      }).catch(() => {
        this.throwError()
      })
    },
    getArtifacts() {
      this.$maxios('get', `/builds/${this.id}/artifacts`).then(response => {
        const artifacts = response.data
        this.artifactsToBeRetrieved = artifacts.length
        for (const artifact of artifacts) {
          this.$maxios('get', `/builds/${this.id}/artifacts/${artifact.name}`).then(response => {
            let artifactCopy = JSON.parse(JSON.stringify(artifact))
            artifactCopy.downloadToken = response.data.token
            this.artifacts.push(artifactCopy)
            this.artifacts.sort((a, b) => a.name.localeCompare(b.name))
          }).catch(() => {
            this.throwError()
          })
        }
      }).catch(() => {
        this.throwError()
      })
    },
    throwError() {
      this.$alerts.setAlertMessage("Couldn't retrieve the requested firmware", 2, 'warning')
      this.$router.push({name: 'home'})
    }
  },
  computed: {
    buildRetrieved() {
      return this.artifactsToBeRetrieved === this.artifacts.length;
    },
    expiredMessage() {
      if (this.artifactsExpired) {
        return " (Expired)"
      }
      return ""
    }
  },
}
</script>

<style scoped>
.building-title {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 20px;
}

.expired a {
  pointer-events: none;
}

.expired a:link {
  color: grey;
}


</style>