<template>
  <b-navbar toggleable="lg" type="dark" variant="dark" style="border-radius: 4px" class="py-0">
    <b-navbar-brand :to="{ name: 'home' }" id="logo">
      <svg id="logo-pic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 226 57.2" style="max-height: 2.5rem">
        <path class="battery"
              d="M217.8 21.1h-1.5c-.2 0-.3 0-.5.1v-5c0-5.2-4.2-9.4-9.4-9.4H16.8c-5.2 0-9.4 4.2-9.4 9.4v26.9c0 5.2 4.2 9.4 9.4 9.4h189.7c5.2 0 9.4-4.2 9.4-9.4v-4.9c.1.1.3.1.5.1h1.5c.6 0 1.1-.5 1.1-1.1v-15c-.1-.7-.6-1.1-1.2-1.1z"/>
        <path class="text" fill="white"
              d="M15.4 44.5V15h13.1l4.7 21.7L38 15h12.9v29.5h-7.6V19.8l-6.2 24.6h-8L23 20.2v24.3h-7.6zM53.9 15H62v23.1h11.5V15h8.1v11.4c0 5.1-.1 8.4-.2 9.8s-.5 2.8-1 4c-.8 1.7-2.1 2.9-3.9 3.5-1.8.6-4.5 1-8.4 1-3.8 0-6.6-.2-8.1-.5-1.6-.4-2.9-1.1-3.9-2.2-.8-.9-1.3-2-1.7-3.5-.4-1.5-.6-3.3-.6-5.5V15zm29.6 29.5l9.2-15.2L83.8 15H93l6.3 10.5 6-10.5h8.9l-8.9 14.6 9.2 14.9h-9.2l-6.8-10.8L92 44.5h-8.5zm34.9 0v-6.6h18.1v-4.8h-6.9c-2.9 0-4.7 0-5.6-.1-.9-.1-1.8-.4-2.7-.8-1.3-.6-2.2-1.6-2.9-2.9-.7-1.4-1-3.1-1-5.1 0-1.8.3-3.4 1-4.8.6-1.4 1.6-2.4 2.7-3.1.8-.5 1.7-.8 2.7-1 1-.2 2.4-.3 4.4-.3h14.4v6.6h-17.1v4.8h8.8c1.8 0 3.2.1 4.2.3 1.1.2 2 .6 2.8 1.1 2.3 1.4 3.4 3.9 3.4 7.3 0 1.8-.3 3.3-.9 4.7-.6 1.3-1.4 2.4-2.6 3.1-.9.6-1.8 1-3 1.2s-2.7.4-4.6.4h-15.2zm26.4 0L155.2 15h11.4l10.5 29.5h-8.2L167 39h-12.4l-2.1 5.5h-7.7zm12-12.1h8l-4-11.8-4 11.8zm21.3 12.1V15h13.2l8.7 23.8V15h7.8v29.5H195l-9.1-23.3v23.3h-7.8z"/>
      </svg>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav align="middle">
        <b-nav-item :to="{ name: 'home' }" v-requires-right:[_rightsKey]="'firmware_builds_get'">Builds List</b-nav-item>
        <b-nav-item :to="{ name: 'build' }" v-require-rights:[_rightsKey]="buildRequiredRightsC">Build</b-nav-item>
        <b-nav-item :to="{ name: 'settings' }" v-require-rights:[_rightsKey]="manageRequiredRightsC">Settings</b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <DropdownMenu v-if="services.length > 0">
          <div slot="trigger" class="pb-1">
            <b-icon icon="front" color="white" class="mx-2 clickable icon-color"></b-icon>
          </div>
          <div slot="content">
            <a class="service-container" :href="service.href"
               v-for="service of services" :key="service.name">
              <img :src="service.iconLink" width="22" height="22" alt="">
              <span class="pl-2">{{ service.name }}</span>
            </a>
          </div>
        </DropdownMenu>
        <div class="nav-link signOut" @click="signOut()">Sign Out</div>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import {M_AUTH_SIGN_OUT} from "@gyrobian/mux-auth-core";
import DropdownMenu from "@/util/DropdownMenu";
import {buildRequiredRights, manageRequiredRights} from '@/router/rightCollections';
import {rightUpdateFunctions} from "@/mixins/util";

export default {
  name: 'NavBar',
  mixins: [rightUpdateFunctions],
  methods: {
    signOut() {
      this.$store.commit(M_AUTH_SIGN_OUT)
    }
  },
  data() {
    return {
      services: [],
      buildRequiredRightsC: buildRequiredRights,
      manageRequiredRightsC: manageRequiredRights
    }
  },
  mounted() {
    this.services = JSON.parse(process.env.VUE_APP_SERVICES)
  },
  components: {
    DropdownMenu
  }
}
</script>

<style scoped>
#logo:hover .battery {
  fill: teal;
}

#logo .battery {
  fill: black;
  transition: fill 250ms;
}

.service-container {
  display: flex;
  text-decoration: unset;
  color: white;
  cursor: pointer;
  padding: 5px 10px;
}

.light .service-container {
  color: #2c3e50;
}

.signOut {
  color: rgb(246, 249, 252) !important;
  cursor: pointer;
}
.show .signOut {
  border-top: 1px solid #909090;
}
</style>