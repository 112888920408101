<template>
  <div class="d-flex justify-content-center">
    <div class="d-flex flex-column justify-content-center" style="height: 100vh">

      <div class="text-center">
        <div style="font-weight: 600; font-size: 18px">404: Not Found!</div>
        <div class="text-muted">How did you even find your way here.</div>
        <router-link :to="{ name: 'home' }" style="color: blue !important;">Take me back</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>