<template>
  <div>
    <BreadCrumbs></BreadCrumbs>
    <div class="content-box">
      <div class="box-header">
        <h5>Register Firmware</h5>
      </div>
      <b-form ref="form" @submit.prevent="" @change="verified = false">
        <b-form-group label="Name" label-size="sm">
          <b-form-input size="sm" type="text" v-model="name" required></b-form-input>
        </b-form-group>

        <b-form-group label="Maintainer email" label-size="sm"
                      description="This email will be used for reporting errors and such regarding this firmware.">
          <b-form-input size="sm" type="email" autocomplete="email" v-model="maintainerEmail" required></b-form-input>
        </b-form-group>

        <b-form-group label="Firmware Type" label-size="sm">
          <b-form-select size="sm" v-model="type" required>
            <b-select-option v-for="type of types" :key="type.value" :value="type.value">
              {{ type.text }}
            </b-select-option>
          </b-form-select>
        </b-form-group>

        <SourceForm v-model="source"/>

        <template v-if="canVerifyOrSubmit">
          <div class="box-actions">
            <b-button @click="verify()" size="sm" variant="primary" :disabled="verified || verifying">
              <template v-if="verified">Verified
                <b-icon icon="check"></b-icon>
              </template>
              <template v-else-if="verifying">
                <b-spinner small></b-spinner>
              </template>
              <template v-else>Verify</template>
            </b-button>
            <b-button @click="submit()" size="sm" variant="success" class="ml-2"
                      v-if="this.verified && !this.verifying" :disabled="submitting">
              <template v-if="submitting">
                <b-spinner small></b-spinner>
              </template>
              <template v-else>Submit</template>
            </b-button>
          </div>
        </template>
        <input hidden ref="formSubmit" type="submit">
      </b-form>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/util/BreadCrumbs";
import SourceForm from "@/components/settings/SourceForm";
import "@/assets/styles/general.css";

export default {
  name: "RegisterFirmware",
  components: {BreadCrumbs, SourceForm},
  data() {
    return {
      name: "",
      maintainerEmail: "",
      type: "",
      types: [],
      versioningStrategy: "CONFIG_DEFINED",
      source: {
        sourceType: "",
        directory: "",
        repositoryUrl: "",
        branchName: "",
        credentialType: "",
        httpAccessUsername: "",
        httpAccessToken: "",
      },
      verified: false,
      verifying: false,
      submitting: false,
    }
  },
  computed: {
    canVerifyOrSubmit() {
      return true;
    }
  },
  mounted() {
    this.fetchFirmwareTypes()
  },
  methods: {
    fetchFirmwareTypes() {
      this.$maxios('get', `/builds/types`).then((response) => {
        this.types = []
        for (const type of response.data) {
          this.types.push({text: type, value: type})
        }
      })
    },
    buildSourceObject() {
      let source = {}
      source.type = this.source.sourceType
      if (this.source.sourceType === "directory") {
        source.directory = this.source.directory
      }
      if (this.source.sourceType === "git-repository") {
        source.repositoryUrl = this.source.repositoryUrl
        source.branchName = this.source.branchName
        source.credentials = {}
        source.credentials.type = this.source.credentialType
        if (this.credentialType === "http-access-token") {
          source.credentials.username = this.source.httpAccessUsername
          source.credentials.token = this.source.httpAccessToken
        }
        // if (this.credentialType === "ssh-key") We dont do anything here yet
      }
      return source
    },
    buildFirmwareObject() {
      let firmware = {}
      firmware.name = this.name
      firmware.maintainerEmail = this.maintainerEmail
      firmware.type = this.type
      firmware.versioningStrategy = this.versioningStrategy
      firmware.source = this.buildSourceObject()
      return firmware
    },
    verify() {
      this.$refs.formSubmit.click()
      if (this.$refs.form.checkValidity()) {
        this.verifying = true
        console.log(this.buildSourceObject())
        this.$maxios('post', '/firmware/verifySource', this.buildSourceObject()).then(response => {
          if (response.data.valid) {
            this.verified = true
            this.verifying = false
          } else {
            this.verified = false
            this.verifying = false
          }
        }).catch(() => {
          this.verified = false
          this.verifying = false
        })
      }
    },
    submit() {
      this.submitting = true
      this.$maxios('post', 'firmware', this.buildFirmwareObject()).then(() => {
        this.submitting = false
        this.$router.push({name: 'settings'})
      })
    }
  }
}
</script>

<style scoped>
</style>