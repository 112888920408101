<template>
  <div v-if="settings">
    <BreadCrumbs></BreadCrumbs>
    <div class="content-box">
      <div class="box-header">
        <h5 class="box-title">General</h5>
      </div>
      <b-form-group label="Allow Builds" label-size="sm" label-cols="12" label-cols-md="2" content-cols="12"
                    content-cols-md="6" description="Can disable all builds across the platform.">
        <b-checkbox switch v-model="buildEnabled" @change="toggleAllowBuilds()"></b-checkbox>
      </b-form-group>
      <b-form-group label="Manage Access" label-size="sm" label-cols="12" label-cols-md="2" content-cols="12"
                    content-cols-md="6">
        <div style="padding-top: 3px">
          <a style="font-size: 14px" href="https://erp.muxsan.com/administration/users">ERP System
            <b-icon class="ml-1" variant="primary" icon="box-arrow-up-right"></b-icon>
          </a>
        </div>
      </b-form-group>
    </div>
    <div class="content-box">
      <div class="box-header ">
        <h5 class="box-title">Registered Firmware</h5>
        <b-row>
          <b-col cols="12" md="8" class="d-flex justify-content-end">
            <b-checkbox switch v-model="showArchived" @change="showArchivedChanged()">
              <span class="no-text-wrap">Show Archived</span>
            </b-checkbox>
          </b-col>
          <b-col cols="12" md="4" class="d-flex justify-content-end">
            <router-link :to="{name: 'firmware-register'}">
              <b-button size="sm" variant="primary">Register</b-button>
            </router-link>
          </b-col>
        </b-row>
      </div>
      <template v-if="firmwares.length">
        <div v-for="firmware of firmwares" :key="firmware.id" class="firmware-item">
          <b-row class="">
            <b-col cols="12" md="7" class="firmware-info d-flex flex-column">
              <div class="firmware-name">{{ firmware.name }}</div>
              <div class="pl-2 firmware-extra-info">Type: {{ firmware.type }}</div>
              <div class="pl-2 firmware-extra-info">Status:
                <template v-if="firmware.status === 'NOMINAL'">
                  <b-icon icon="check"></b-icon>
                </template>
                <template v-if="firmware.status === 'REFRESHING'">
                  <b-spinner small></b-spinner>
                </template>
              </div>
              <div class="pl-2 firmware-extra-info" v-if="firmware.archived">
                <b-badge pill>Archived</b-badge>
              </div>
            </b-col>
            <b-col cols="12" md="5" class="firmware-actions my-auto d-flex justify-content-end">
              <router-link :to="{ name: 'firmware-overview', params: { id: firmware.id } }">
                <b-button size="sm" variant="primary">
                  <b-icon icon="info"></b-icon>
                </b-button>
              </router-link>
              <b-button size="sm" variant="secondary" class="ml-2"
                        v-if="firmware.archived"
                        @click="unarchiveFirmware(firmware)" title="Unarchive">
                <b-iconstack font-scale="1" style="font-size: 0.875rem;">
                  <b-icon stacked icon="archive" scale=".75"></b-icon>
                  <b-icon stacked icon="slash-circle" scale="1.25"></b-icon>
                </b-iconstack>
              </b-button>
              <b-button v-else size="sm" variant="secondary" class="ml-2"
                        @click="archiveFirmware(firmware)" title="Archive">
                <b-icon icon="archive"></b-icon>
              </b-button>
            </b-col>
          </b-row>
        </div>
        <b-pagination v-if="rows/perPage>1"
                      align="center" class="mt-3" size="sm" pills
                      v-model="currentPage" :per-page="perPage" :total-rows="rows"
        ></b-pagination>
      </template>
      <template v-else>
        <div class="d-flex justify-content-center py-3">
          <b-spinner small variant="primary"></b-spinner>
        </div>
      </template>
      <div>
        <b-table
            hidden
            ref="hiddenTable"
            :items="provider"
            :per-page="perPage"
            :current-page="currentPage"
        ></b-table>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/styles/general.css";
import BreadCrumbs from "@/components/util/BreadCrumbs";

export default {
  name: "Settings",
  components: {BreadCrumbs},
  data() {
    return {
      firmwares: [],
      settings: null,
      buildEnabled: false,
      currentPage: 1,
      perPage: 8,
      rows: 0,
      showArchived: true
    }
  },
  methods: {
    provider(ctx, callback) {
      let archivedPart = ""
      if (!this.showArchived) {
        archivedPart = `archived=${this.showArchived}`
      }
      this.$maxios('get', `firmware?${archivedPart}&page=${ctx.currentPage - 1}&size=${ctx.perPage}`)
          .then(response => {
            // Pluck the array of items off our axios response
            this.pageData = response.data
            const items = response.data.content
            this.rows = response.data.totalElements
            this.firmwares = response.data.content
            // Provide the array of items to the callback
            callback(items)
          })
          .catch(() => {
            callback([])
          })
    },
    fetchSettings() {
      this.$maxios('get', `settings`).then((response) => {
        this.settings = response.data
        this.buildEnabled = response.data['builds-enabled']
      })
    },
    unarchiveFirmware(firmware) {
      this.$maxios('post', `firmware/${firmware.id}/unarchive`).then(() => {
        this.$refs.hiddenTable.refresh()
      })
    },
    archiveFirmware(firmware) {
      this.$maxios('post', `firmware/${firmware.id}/archive`).then(() => {
        this.$refs.hiddenTable.refresh()
      })
    },
    showArchivedChanged() {
      this.$nextTick(() => this.$refs.hiddenTable.refresh())
    },
    toggleAllowBuilds() {
      const currentValue = this.settings['builds-enabled']
      this.$maxios('post', `/settings/builds-enabled?enabled=${!currentValue}`).then(() => {
        this.settings['builds-enabled'] = !currentValue
        this.buildEnabled = !currentValue
      }).catch(() => {
        this.settings['builds-enabled'] = currentValue
        this.buildEnabled = currentValue
        this.$alerts.setAlertMessage("Unable to change the allow builds variable.", 2, 'warning')
      })
    }
  },
  mounted() {
    this.fetchSettings()
  },
}
</script>

<style scoped>
.no-text-wrap {
  white-space: nowrap;
}

.firmware-item:not(:last-of-type) {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.firmware-name {
  font-weight: bold;
  font-size: 90%;
  color: #6c757d;
}

.firmware-extra-info {
  color: #6c757d !important;
  font-size: 80%;
  font-weight: 400;
}
</style>