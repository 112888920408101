<template>
  <main>
    <b-container class="wrapped-container">
      <NavBar></NavBar>
      <router-view></router-view>
      <div class="push"></div>
    </b-container>
    <footer>
      <small class="text-muted">Version: {{ version }} ~ Designed and Developed By
        <a target="_blank" href="https://www.gyrobian.nl">Gyrobian</a>
      </small>
    </footer>
    <Alerts ref="alerts"></Alerts>
  </main>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Alerts from '@/components/util/Alert'
import Vue from 'vue'
import {version} from '../package.json';

export default {
  name: 'FirmwareManager',
  components: {NavBar, Alerts},
  data() {
    return {
      version: version + process.env.VUE_APP_FRONT_END_VERSION_APPEND
    }
  },
  mounted() {
    Vue.prototype.$alerts = this.$refs.alerts
  }
}
</script>

<style>
html {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
}
body {
  height: 100%;
}

.router-link-exact-active  {
  color: #f6f9fc !important;
}
</style>

<style scoped>
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
.push {
  padding-top: 40px;
}
main {
  height: 100%;
  margin: 0;
}
.wrapped-container {
  padding-top: .5em;
  min-height: 100%;
  max-width: 800px;

  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -40px;
}
</style>