<template>
  <div class="d-flex justify-content-center">
    <div class="d-flex flex-column justify-content-center" style="height: 100vh">

      <div class="text-center">
        <div style="font-weight: 600; font-size: 18px">403: Forbidden</div>
        <div class="text-muted">You either have no access to this service or you don't have the required rights.</div>
        <a :href="auth" style="color: blue !important; text-transform: capitalize">Back to services overview</a>
        <div class="pt-4">Or try to refresh
          <router-link style="color: blue !important" :to="{name: 'home'}" class="text-muted">here.</router-link>
          if you have been granted access.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Forbidden",
  data() {
    return {
      auth: process.env.VUE_APP_AUTH_URL + '/services'
    }
  }
}
</script>

<style scoped>

</style>