<template>
  <b-breadcrumb :items="crumbs" class="mt-3 crumb-container">
  </b-breadcrumb>
</template>

<script>
export default {
  name: "BreadCrumbs",
  data() {
    return {
      crumbs: []
    }
  },
  mounted() {
    if (this.$route.meta.crumbs !== undefined) {
      this.crumbs = this.$route.meta.crumbs
      for (const crumb of this.crumbs) {
        const split = crumb.text.split(':')
        if (split[1] === 'id') {
          const prefix = split[0] !== '' ? split[0] + ' / ' : ''
          crumb.text = prefix + this.$route.params.id
        }
      }
    }
  }
}
</script>

<style scoped>
.divider {
  margin: 0 5px;
}
.crumb-container {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
</style>