<template>
  <div class="dropdown-container">
    <div @click="toggle()">
      <slot name="trigger" :class="{ 'light' : !dark, 'small' : small }"></slot>
    </div>
    <transition name="slide-fade">
      <div v-if="show" @click="forceClose()" class="dropdown" :class="{ 'light' : !dark, 'small' : small }">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "DropdownMenu",
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      show: false
    }
  },
  methods: {
    toggle () {
      this.show = !this.show
    },
    close (e) {
      if (!this.$el.contains(e.target)) {
        this.show = false
      }
    },
    forceClose() {
      this.show = false
    }
  },
  mounted () {
    document.addEventListener('click', this.close)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.close)
  }

}
</script>

<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all .2s ease-in-out;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}

.dropdown-container {
  position: relative;
  display: flex;
  align-items: center;
}

.dropdown {
  position: absolute;
  width: auto;
  top: 100%;
  z-index: 300;
  min-width: 240px;
  max-width: 500px;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
  background-color: #172b4d;
  color: white;
  padding: .5em 0;
  border-radius: 0.35rem;
  box-shadow: 0 4px 4px rgba(0,0,0,0.1);
  text-align: left;
  right: 0;
  left: auto;
}
.dropdown.small {
  min-width: 200px !important
}

.dropdown.light {
  color: rgb(53, 50, 60);
  background-color: white;
  border: 1px solid #dbdbdb;
}

.dropdown.show {
  display: block;
}


</style>