<template>
  <div>
    <div class="mt-3 d-flex justify-content-center">
      <h4>Previous builds.</h4>
    </div>
    <Table api-url="/builds" :fields="fields"
           title="Completed Builds"
           overview-link-key="id"
           overview-link-route-name="build-result"
           overview-required-rights="firmware_builds_get,firmware_builds_get-artifacts"
           delete-required-rights="firmware_builds_remove"
           small
    ></Table>
  </div>
</template>

<script>
import Table from "@/components/Table";

export default {
  name: "BuildsList",
  components: {Table},
  data() {
    return {
      fields: [
        // {
        //   key: 'createdAt',
        //   sortable: false,
        //   formatter: (element) => new Date(element).toLocaleString()
        // },
        {
          key: 'firmwareVersion.firmware.name',
          label: "Firmware"
        },
        {
          key: 'firmwareVersion.name',
          label: 'Version'
        },
        {
          key: 'firmwareVersion.firmware.type',
          sortable: false,
          label: 'Type'
        },
        {
          key: 'exitCode',
          label: 'success',
          formatter: (element) => {
            return element === 0 ? '✓' : 'x'
          },
          class: 'font-weight-bold text-center'
        },
        'Options'
      ]
    }
  }
}
</script>

<style scoped>

</style>