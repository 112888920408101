<template>
  <div>
    <BreadCrumbs></BreadCrumbs>
    <div class="content-box" v-if="firmware && webhookTokenURL">
      <div class="box-header">
        <h5 class="box-title">{{ firmware.name }}</h5>
        <div>
          <b-badge variant="secondary" pill v-if="firmware.archived">Archived</b-badge>
        </div>
      </div>
      <div>
        <b-form-group class="mt-2" label="Allow builds" label-size="sm"
                      description="If off will disable all builds of this firmware.">
          <b-checkbox switch v-model="buildsEnabled" @change="toggleAllowBuilds()"></b-checkbox>
        </b-form-group>
        <b-form-group class="mt-2" label="Firmware identifier" label-size="sm">
          <b-form-input size="sm" :value="firmware.id" disabled></b-form-input>
        </b-form-group>
        <b-form-group class="mt-2" label="Maintainer" label-size="sm">
          <b-form-input size="sm" :value="firmware.maintainerEmail" disabled></b-form-input>
        </b-form-group>
        <b-form-group class="mt-2" label="Type" label-size="sm">
          <b-form-input size="sm" :value="firmware.type" disabled></b-form-input>
        </b-form-group>
        <b-form-group class="mt-2" label="Latest Version" label-size="sm">
          <b-form-input size="sm" :value="firmware.latestVersion" disabled></b-form-input>
        </b-form-group>
        <b-form-group class="mt-2" label="Webhook URL" label-size="sm"
                      description="This URL can be used by a repository to fire update webhooks.">
          <b-form-input size="sm" :value="webhookTokenURL" disabled></b-form-input>
        </b-form-group>
        <b-button @click="copyWebhookToClipboard" size="sm" variant="primary">Copy URL</b-button>
        <template v-if="firmware.source.repositoryUrl">
          <b-form-group class="mt-2" label="Repository URL" label-size="sm">
            <b-form-input size="sm" :value="firmware.source.repositoryUrl" disabled></b-form-input>
          </b-form-group>
          <b-form-group class="mt-2" label="Tracked branch" label-size="sm">
            <b-form-input size="sm" :value="firmware.source.branchName" disabled></b-form-input>
          </b-form-group>
        </template>
        <template v-if="firmware.source.directory">
          <b-form-group class="mt-2" label="Directory" label-size="sm">
            <b-form-input size="sm" :value="firmware.source.directory" disabled></b-form-input>
          </b-form-group>
        </template>
      </div>
      <div class="box-actions">
        <b-button size="sm" variant="primary" v-if="firmware.status === 'NOMINAL'" title="Update Sources"
                  @click="showConfirmUpdateSources(firmware)" class="ml-2">
          <b-icon icon="download"></b-icon>
        </b-button>
        <router-link :to="{name: 'firmware-edit'}">
          <b-button size="sm" variant="primary" v-if="firmware.status === 'NOMINAL'" class="ml-2">
            Edit Info
          </b-button>
        </router-link>
        <router-link :to="{name: 'source-edit'}">
          <b-button size="sm" variant="primary" v-if="firmware.status === 'NOMINAL'" class="ml-2">
            Edit Source
          </b-button>
        </router-link>
      </div>
    </div>
    <div class="content-box">
      <div class="box-header ">
        <h5 class="box-title">Tracked Versions</h5>
      </div>
      <template v-if="versions && versions.length">
        <div v-for="version of versions" :key="version.id" class="version-item">
          <b-row class="">
            <b-col cols="12" md="8" class="version-info d-flex flex-column">
              <div class="version-name">Version: {{ version.name }}</div>
              <div class="pl-2 version-extra-info">Registerd At: {{
                  new Date(version.registeredAt).toLocaleString()
                }}
              </div>
              <div class="pl-2 version-extra-info" v-if="version.archived">
                <b-badge pill>Archived</b-badge>
              </div>
            </b-col>
            <b-col cols="12" md="4" class="version-actions my-auto d-flex justify-content-end">
              <b-button size="sm" variant="secondary" class="ml-2"
                        v-if="version.archived"
                        @click="unarchiveFirmware(version)">Unarchive
              </b-button>
              <b-button v-else size="sm" variant="secondary" class="ml-2"
                        @click="archiveFirmware(version)">Archive
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
      <template v-else>
        Not tracking any versions yet.
      </template>
    </div>
  </div>
</template>

<script>
import "@/assets/styles/general.css";
import BreadCrumbs from "@/components/util/BreadCrumbs";

export default {
  name: "FirmwareOverview",
  components: {BreadCrumbs},
  data() {
    return {
      firmware: null,
      webhookTokenURL: null,
      versions: null,
      buildsEnabled: false
    }
  },
  mounted() {
    this.fetchFirmware()
  },
  methods: {
    setRefreshLoop() {
      setTimeout(() => {
        this.fetchFirmware()
      }, this.firmware.status === 'NOMINAL' ? 150000 : 5000)
    },
    copyWebhookToClipboard() {
      navigator.clipboard.writeText(this.webhookTokenURL);
      this.$alerts.setAlertMessage("Copied key to clipboard", 1)
    },
    fetchFirmware() {
      const id = this.$route.params.id
      this.$maxios('get', `firmware/${id}/webhookAccessToken`).then(response => {
        this.webhookTokenURL = process.env.VUE_APP_API_URL + `/firmware/${id}/` + response.data.token
      })
      this.$maxios('get', `/firmware/${id}`).then(firmwareResponse => {
        this.$maxios('get', `/firmware/${id}/versions`).then(versionsResponse => {
          this.versions = versionsResponse.data.content
          this.firmware = firmwareResponse.data
          this.buildsEnabled = firmwareResponse.data.buildsEnabled
          this.setRefreshLoop()
        }).catch(() => {
          this.throwError()
        })
      }).catch(() => {
        this.throwError()
      })
    },
    updateFirmware(firmware) {
      this.$maxios('post', `firmware/${firmware.id}/refresh`).then(() => {
        this.fetchFirmware()
      })
    },
    throwError() {
      this.$alerts.setAlertMessage("Couldn't retrieve the requested firmware", 2, 'warning')
      this.$router.push({name: 'settings'})
    },
    unarchiveFirmware(version) {
      const url = `firmware/${this.firmware.id}/versions/${version.name}/unarchive`
      this.$maxios('post', url).then(() => {
        this.fetchFirmware()
      })
    },
    archiveFirmware(version) {
      const url = `firmware/${this.firmware.id}/versions/${version.name}/archive`
      this.$maxios('post', url).then(() => {
        this.fetchFirmware()
      })
    },
    showConfirmUpdateSources(firmware) {
      this.$bvModal.msgBoxConfirm('Updating the firmware sources will make it unavailable for builds until the update finishes.', {
        title: "Please Confirm",
        size: "md",
        buttonSize: "sm",
        hideHeaderClose: false,
        centered: true
      })
          .then(value => {
            console.log(value)
            if (value === true) {
              this.updateFirmware(firmware)
            }
          })
          .catch(err => {
            console.log(err)
            // An error occurred
          })
    },
    toggleAllowBuilds() {
      const currentValue = this.firmware.buildsEnabled
      this.$maxios('post', `/firmware/${this.firmware.id}/buildsEnabled?enabled=${!currentValue}`).then(() => {
        this.firmware.buildsEnabled = !currentValue
        this.buildEnabled = !currentValue
      }).catch(() => {
        this.firmware.buildsEnabled = currentValue
        this.buildsEnabled = currentValue
        this.$alerts.setAlertMessage("Unable to change the allow builds variable.", 2, 'warning')
      })
    }
  },
}
</script>

<style scoped>
.version-item:not(:last-of-type) {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.version-name {
  font-weight: bold;
  font-size: 90%;
  color: #6c757d;
}

.version-extra-info {
  color: #6c757d !important;
  font-size: 80%;
  font-weight: 400;
}
</style>