<template>
  <div>
    <!-- Source section -->
    <b-form-group label="Source" label-size="sm">
      <b-form-select size="sm" v-model="source.sourceType" required>
        <b-select-option v-for="type of sourceTypes" :key="type.value" :value="type.value" @change="handleInput">
          {{ type.text }}
        </b-select-option>
      </b-form-select>
    </b-form-group>

    <!-- Source type Git Repo -->
    <template v-if="source.sourceType === 'git-repository'">
      <b-form-group label-size="sm" label="Repository URL"
                    description="Make sure the URL matches the credential type.">
        <b-input v-model="source.repositoryUrl" required type="text" size="sm" @change="handleInput"
                 :state="isUrlValidWithCredential"></b-input>
      </b-form-group>
      <b-form-group label-size="sm" label="Branch" description="For example: 'main' or 'master'">
        <b-input v-model="source.branchName" required type="text" size="sm" @change="handleInput"></b-input>
      </b-form-group>
      <b-form-group label="Credential Type" label-size="sm">
        <b-form-select size="sm" v-model="source.credentialType" required @change="handleInput">
          <b-select-option v-for="type of credentialTypes" :key="type.value" :value="type.value">
            {{ type.text }}
          </b-select-option>
        </b-form-select>
      </b-form-group>
      <template v-if="source.credentialType === 'http-access-token'">
        <b-form-group label="Username" label-size="sm">
          <b-form-input size="sm" v-model="source.httpAccessUsername" required @change="handleInput"></b-form-input>
        </b-form-group>
        <b-form-group label="Token" label-size="sm">
          <b-form-input size="sm" v-model="source.httpAccessToken" required @change="handleInput"></b-form-input>
        </b-form-group>
      </template>
      <template v-if="source.credentialType === 'ssh-key'">
        <b-form-group label="Token" label-size="sm"
                      description="Add this key to the repository' SSH keys.">
          <b-form-textarea disabled no-resize style="background: white" :value="serverToken"
                           rows="8"
          ></b-form-textarea>
        </b-form-group>
        <div class="d-flex ">
          <b-button @click="copyKeyToClipboard" size="sm" variant="primary">Copy Key</b-button>
        </div>
      </template>
    </template>

    <!-- Source type Directory -->
    <template v-if="source.sourceType === 'directory'">
      <b-form-group label="Directory" label-size="sm">
        <b-form-input size="sm" type="text" v-model="source.directory" required @change="handleInput"></b-form-input>
      </b-form-group>
    </template>
  </div>
</template>

<script>
export default {
  name: "SourceForm",
  props: {
    value: Object
  },
  data() {
    return {
      source: this.value,
      sourceTypes: [
        {
          value: "git-repository",
          text: "Git Repository"
        },
        {
          value: "directory",
          text: "Local Directory"
        }
      ],
      credentialTypes: [
        {
          value: "ssh-key",
          text: "SSH Key",
        },
        {
          value: "http-access-token",
          text: "HTTP Access Token",
        }
      ],
      serverToken: "",
    }
  },
  computed: {
    isUrlValidWithCredential() {
      if (this.source.credentialType) {
        if (this.source.credentialType === 'ssh-key') {
          return this.source.repositoryUrl.includes('git@') &&
          this.source.repositoryUrl.includes('.git') ? null : false
        }
        if (this.source.credentialType === 'http-access-token') {
          return this.source.repositoryUrl.includes('https://') &&
          this.source.repositoryUrl.includes('.git') ? null : false
        }
        return null
      }
      return null
    }
  },
  methods: {
    copyKeyToClipboard() {
      navigator.clipboard.writeText(this.serverToken);
      this.$alerts.setAlertMessage("Copied key to clipboard", 1)
    },
    handleInput() {
      this.$emit('input', JSON.parse(JSON.stringify(this.source)))
    }
  },
  mounted() {
    this.$maxios('get', '/firmware/sshKey').then(response => {
      this.serverToken = response.data.publicKey
    })
  }
}
</script>

<style scoped>

</style>