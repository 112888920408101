<template>
  <div v-if="firmware">
    <BreadCrumbs></BreadCrumbs>
    <div class="content-box">
      <div class="box-header">
        <h5>Edit Info</h5>
      </div>
      <b-form ref="form" @submit.prevent="saveFirmwareInfo()">
        <b-form-group label="Name" label-size="sm">
          <b-form-input size="sm" type="text" v-model="firmware.name" required></b-form-input>
        </b-form-group>

        <b-form-group label="Maintainer email" label-size="sm"
                      description="This email will be used for reporting errors and such regarding this firmware.">
          <b-form-input size="sm" type="email" autocomplete="email" v-model="firmware.maintainerEmail" required></b-form-input>
        </b-form-group>

        <b-form-group label="Firmware Type" label-size="sm">
          <b-form-select size="sm" v-model="firmware.type" required>
            <b-select-option v-for="type of types" :key="type.value" :value="type.value">
              {{ type.text }}
            </b-select-option>
          </b-form-select>
        </b-form-group>
      </b-form>

      <div class="box-actions">
        <b-button @click="saveFirmwareInfo()" size="sm" variant="success" class="ml-2"
                  :disabled="needsSaving || submitting">
          <template v-if="submitting">
            <b-spinner small></b-spinner>
          </template>
          <template v-else>Submit</template>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/util/BreadCrumbs";
export default {
  name: "EditFirmware",
  components: {BreadCrumbs},
  data() {
    return {
      firmware: null,
      types: [],
      firmwareCopy: '',
      submitting: false,
    }
  },
  computed: {
    needsSaving() {
      return this.firmwareCopy === JSON.stringify(this.firmware);
    }
  },
  methods: {
    fetchFirmware() {
      this.$maxios('get', `firmware/${this.$route.params.id}`).then((response) => {
        this.firmware = response.data
        this.firmwareCopy = JSON.stringify(this.firmware)
      })
    },
    fetchFirmwareTypes() {
      this.$maxios('get', `/builds/types`).then((response) => {
        this.types = []
        for (const type of response.data) {
          this.types.push({text: type, value: type})
        }
      })
    },
    saveFirmwareInfo() {
      this.$maxios('patch', `firmware/${this.$route.params.id}`, this.firmware).then(() => {
        this.$router.push({name: 'firmware-overview'})
      }).catch((err) => {
        console.log(err)
        this.$alerts.setAlertMessage("Something went wrong trying to save this firmware", 2, 'danger')
      })
      console.log(this.firmware)
    }
  },
  mounted() {
    this.fetchFirmware()
    this.fetchFirmwareTypes()
  },
}
</script>

<style scoped>

</style>