export const buildRequiredRights = [
    'firmware_builds_get',
    'firmware_builds_create',
    'firmware_builds_get-artifacts',
].join(',')

export const manageRequiredRights = [
    'firmware_builds_get-types',
    'firmware_builds_remove',
    'firmware_firmwares_get',
    'firmware_firmwares_remove',
    'firmware_firmwares_create',
    'firmware_firmwares_update',
    'firmware_versions_get',
    'firmware_versions_update'
].join(',')