<template>
  <div class="mt-3">
    <b-table class="mb-0"
             :busy="isBusy"
             id="data-table"
             :filter="filterQuery"
             :items="provider"
             :fields="fields"
             :per-page="perPage"
             :current-page="currentPage"
             thead-class="text-uppercase text-muted head-style"
             hover
             :small="small"
             bordered
    >
      <template #cell(options)="row" v-if="overviewLinkKey">
        <div class="d-flex justify-content-center">
          <router-link
              v-requires-right:[_rightsKey]="overviewRequiredRights"
              v-if="overviewLinkRouteName" :to="{ name: overviewLinkRouteName,
            params: { id: row.item[overviewLinkKey] },
            query: { oldBuild: true }}" class="px-1">
            <b-icon icon="info-square" color="black" class="clickable icon-color"></b-icon>
          </router-link>
          <slot name="custom-edit-action">
            <router-link
                v-if="editLinkRouteName" :to="{ name: editLinkRouteName,
                params: { id: row.item[overviewLinkKey] }}" title="Edit" class="px-1">
              <b-icon icon="pencil-square" color="black" class="clickable icon-color"></b-icon>
            </router-link>
          </slot>
          <div class="px-1">
            <b-icon v-requires-right:[_rightsKey]="deleteRequiredRights"
                    icon="trash" @click="deleteItem(row.item[overviewLinkKey], row.item)"
                    class="clickable icon-color"></b-icon>
          </div>
        </div>
      </template>
      <template #table-busy>
        <div class="text-center my-2" style="color: #5e72e4">
          <b-spinner class="align-middle"></b-spinner>
          <span class="sr-only">Loading</span>
        </div>
      </template>
    </b-table>
    <b-pagination v-if="rows/perPage > 1"
                  align="center" class="mt-3" size="sm" pills
                  v-model="currentPage" :per-page="perPage" :total-rows="rows"
    ></b-pagination>
  </div>
</template>

<script>
import '@/assets/styles/table.css'
import {rightUpdateFunctions} from "@/mixins/util";

export default {
  name: "Table",
  mixins: [rightUpdateFunctions],
  props: {
    title: {
      type: String,
      default: 'Items'
    },
    subTitle: {
      type: String,
      default: ''
    },
    apiUrl: {
      type: String
    },
    fields: {
      type: Array
    },
    overviewLinkKey: {
      type: String,
      default: null
    },
    overviewLinkRouteName: {
      type: String,
      default: null
    },
    overviewRequiredRights: {
      type: String,
      default: ''
    },
    deleteRequiredRights: {
      type: String,
      default: ''
    },
    editLinkRouteName: {
      type: String,
      default: null
    },
    searchable: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    perPageCustomizable: {
      type: Boolean,
      default: false
    },
    pageOptions: {
      type: Array,
      default: () => [5, 10, 25, 100]
    }
  },
  data() {
    return {
      isBusy: false,
      currentPage: 1,
      rows: 0,
      filterQuery: '',
      pageData: null,
      perPage: 15
    }
  },
  methods: {
    toggleBusy() {
      this.isBusy = !this.isBusy
    },
    provider(ctx, callback) {
      let sort = ""
      if (ctx.sortBy !== "") {
        sort = `&sort=${ctx.sortBy},${ctx.sortDesc ? 'desc' : 'asc'}`
      }
      if (ctx.filter !== "") {
        sort = `&q=${ctx.filter}`
      }
      this.$maxios('get', `${process.env.VUE_APP_API_URL}${this.apiUrl}?page=${ctx.currentPage - 1}&size=${ctx.perPage}${sort}`)
          .then(response => {
            // Pluck the array of items off our axios response
            this.pageData = response.data
            const items = response.data.content
            this.rows = response.data.totalElements
            // Provide the array of items to the callback
            callback(items)
          })
          .catch(() => {
            callback([])
          })

      // Must return null or undefined to signal b-table that callback is being used
      return null
    },
    refresh() {
      this.currentPage = 1
      this.$root.$emit('bv::refresh::table', 'data-table')
    },
    deleteItem(itemKey) {
      this.$bvModal.msgBoxConfirm(`Are you sure you wish to remove this build?`, {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        cancelVariant: 'secondary',
        okTitle: 'Yes, delete',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
        if (value) {
          this.$maxios('delete', `${this.apiUrl}/${itemKey}`).then(() => {
            this.refresh()
          }).catch(() => {
            this.$alerts.setAlertMessage('An error occurred try again later.', 2, 'danger')
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.dash-card {
  background-color: white;
  padding: 1em;
  border-radius: 8px;
}

.card-body {
  padding: 0 !important;
}
</style>