import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import BuildForm from "@/components/builds/BuildForm";
import Settings from "@/components/settings/Settings";
import FirmwareManager from "@/FirmwareManager";
import BuildResult from "@/components/builds/BuildResult";
import NotFound from "@/components/util/NotFound";
import {verifyAccessParameters} from "@gyrobian/mux-auth-core";
import BuildsList from "@/components/BuildsList";
import RegisterFirmware from "@/components/settings/RegisterFirmware";
import FirmwareOverview from "@/components/settings/FirmwareOverview";
import EditSource from "@/components/settings/EditSource";
import EditFirmware from "@/components/settings/EditFirmware";
import Forbidden from "@/components/util/Forbidden";

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        component: NotFound,
        name: 'notFound'
    },
    {
        path: '/oh-no',
        component: Forbidden,
        name: 'forbidden'
    },
    {
        path: '/',
        component: FirmwareManager,
        redirect: 'home',
        children: [
            {
                path: '/home',
                name: 'home',
                component: BuildsList,
                meta: {
                    crumbs: [
                        { text: 'Home', to: { name: 'home' } },
                        { text: 'List', to: { name: 'list' } }
                    ],
                    requiredRights: 'firmware_builds_get'
                }
            },
            {
                path: '/build',
                name: 'build',
                component: BuildForm,
                meta: {
                    crumbs: [
                        { text: 'Home', to: { name: 'home' } },
                        { text: 'Build', to: { name: 'build' } }
                    ],
                    requiredRights: 'firmware_builds_get,firmware_builds_create,firmware_builds_get-artifacts'
                }
            },
            {
                path: '/build/:id',
                name: 'build-result',
                component: BuildResult,
                meta: {
                    crumbs: [
                        { text: 'Home', to: { name: 'home' } },
                        { text: 'Build', to: { name: 'build' } },
                        { text: ':id', to: { name: 'build-result' } }
                    ],
                    requiredRights: 'firmware_builds_get,firmware_builds_get-artifacts'
                }
            },
            {
                path: '/settings',
                name: 'settings',
                component: Settings,
                meta: {
                    crumbs: [
                        { text: 'Home', to: { name: 'home' } },
                        { text: 'Settings', to: { name: 'settings' } }
                    ],
                    requiredRights: 'firmware_firmwares_get'
                }
            },
            {
                path: '/settings/firmware/register',
                name: 'firmware-register',
                component: RegisterFirmware,
                meta: {
                    crumbs: [
                        { text: 'Home', to: { name: 'home' } },
                        { text: 'Settings', to: { name: 'settings' } },
                        { text: 'Register Firmware', to: { name: 'register-firmware' } }
                    ],
                    requiredRights: 'firmware_firmwares_create'
                }
            },
            {
                path: '/settings/firmware/:id/overview',
                name: 'firmware-overview',
                component: FirmwareOverview,
                meta: {
                    crumbs: [
                        { text: 'Home', to: { name: 'home' } },
                        { text: 'Settings', to: { name: 'settings' } },
                        { text: 'Firmware Overview', to: { name: 'firmware-overview' } }
                    ],
                    requiredRights: 'firmware_firmwares_get,firmware_versions_get'
                }
            },
            {
                path: '/settings/firmware/:id/edit',
                name: 'firmware-edit',
                component: EditFirmware,
                meta: {
                    crumbs: [
                        { text: 'Home', to: { name: 'home' } },
                        { text: 'Settings', to: { name: 'settings' } },
                        { text: ':id', to: { name: 'firmware-overview' } },
                        { text: 'Edit Info', to: { name: 'firmware-overview' } }
                    ],
                    requiredRights: 'firmware_firmwares_get,firmware_firmwares_update'
                }
            },
            {
                path: '/settings/firmware/:id/source/edit',
                name: 'source-edit',
                component: EditSource,
                meta: {
                    crumbs: [
                        { text: 'Home', to: { name: 'home' } },
                        { text: 'Settings', to: { name: 'settings' } },
                        { text: ':id', to: { name: 'firmware-overview' } },
                        { text: 'Edit Source', to: { name: 'firmware-overview' } }
                    ],
                    requiredRights: 'firmware_firmwares_get,firmware_firmwares_update'
                }
            },
        ]
    },
]

function addAuthOnRoute (route) {
    route.beforeEnter = (to, from, next) => verifyAccessParameters(to, from, next, store)
    if (route.children !== undefined) {
        for (const child of route.children) {
            addAuthOnRoute(child)
        }
    }
}

for (const route of routes) {
    addAuthOnRoute(route)
}

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router